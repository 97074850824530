import { FC, useEffect, useState } from "react";
import { Button, Cascader, Col, Form, InputNumber, Row, Select, TreeSelect } from "antd";

import type { SingleValueType } from "rc-cascader/lib/Cascader";
import type { GetQuestionsProp } from "../../utils/apis";

import { toUpperCase } from "../../utils/toUpperCase";
import { sort } from "../../utils/sort";

import "./index.scss";

const { Option } = Select;

interface Option {
  value: string;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
}

const SubjectSelector: FC<{
  exams: Exams;
  setSubject: (s: string) => void;
}> = ({ exams, setSubject }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<Array<string>>([]);

  useEffect(() => {
    if (!exams) return;
    const options = Object.entries(exams as Exams).map((entry) => {
      const [, exam] = entry;
      return {
        label: exam.name,
        value: exam.name,
        children: Object.entries(exam.subjects).map((entry) => {
          const [, subject] = entry;
          return {
            label: subject.name,
            value: subject.name,
          };
        }),
      };
    });
    setOptions(options);
    setValue([]);
  }, [exams]);

  const onChange = (value: SingleValueType) => {
    if (value) {
      const [exam, subject] = value;
      setSubject(`${exam}#${subject}`);
      setValue([String(exam), String(subject)]);
    }
  };

  return (
    <Cascader
      placeholder="select a subject first"
      value={value}
      options={options}
      onChange={onChange}
      changeOnSelect={false}
    />
  );
};

const Selector: FC<{
  exams: Exams;
  setQuery: (q: Query) => void;
}> = ({ exams, setQuery }) => {
  const [exam, setExam] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [tags, setTags] = useState<{ [key: string]: Tag }>({});
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    // console.log("Received values of form: ", values);
    const entries = Object.entries(values)
      .filter(([n, _]) => n !== "subject")
      .filter(([, v]) => v);

    setQuery({
      exam: exam,
      subject: subject,
      properties: entries ? Object.fromEntries(entries as readonly any[]) : {},
    });
  };

  useEffect(() => {
    const tags =
      exam && exams[exam] && subject && exams[exam].subjects[subject] ? exams[exam].subjects[subject].tags : {};
    setTags(tags);
  }, [exams, exam, subject]);

  useEffect(() => {
    setExam("");
    setSubject("");
    form.resetFields();
  }, [exams]);

  const getFields = () => {
    const children = [];
    children.push(
      <Col span={8} key="subject">
        <Form.Item
          name={`subject`}
          label={`Subject`}
          rules={[
            {
              required: true,
              message: "Select target subject to start",
            },
          ]}
        >
          <SubjectSelector
            exams={exams}
            setSubject={(s) => {
              form.setFieldsValue({ subject: s });
              const [exam, subject] = s.split("#");
              setExam(exam);
              setSubject(subject);
            }}
          />
        </Form.Item>
      </Col>
    );

    Object.entries(tags)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .forEach((entry) => {
        const [tagName, tag] = entry;
        children.push(
          <Col span={8} key={tagName}>
            <Form.Item
              name={`${tag.type}#${tag.name}`}
              label={toUpperCase(tag.name)}
              rules={[
                {
                  required: false,
                  message: "Input something!",
                },
              ]}
            >
              {
                {
                  string:
                    tag.type === "string" ? (
                      <Select
                        allowClear
                        placeholder="please select an option"
                        value={tag.default}
                        onChange={(v) => form.setFieldsValue({ [`string#${tag.name}`]: v })}
                      >
                        {(tag as TagStr).options?.sort(sort).map((option) => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <></>
                    ),
                  integer: (
                    <InputNumber
                      placeholder={`${(tag as TagInt).min} - ${(tag as TagInt).max}`}
                      style={{ width: "100%" }}
                      min={(tag as TagInt).min}
                      max={(tag as TagInt).max}
                      value={tag.default}
                      onChange={(v) => form.setFieldsValue({ [`integer#${tag.name}`]: v })}
                    />
                  ),
                  "multi-level":
                    tag.type === "multi-level" ? (
                      <TreeSelect
                        allowClear
                        placeholder="please select an option"
                        onChange={(v) =>
                          form.setFieldsValue({
                            [`multi-level#${tag.name}`]: v,
                          })
                        }
                        treeData={Object.values(tag.options)
                          .sort((a, b) => sort(a.name, b.name))
                          .map(({ name, options }) => {
                            return {
                              title: name,
                              value: name,
                              children: options
                                ? options.sort(sort).map((option) => {
                                    return {
                                      title: option,
                                      value: `${name}@${option}`,
                                    };
                                  })
                                : [],
                            };
                          })}
                      />
                    ) : (
                      <></>
                    ),
                }[tag.type]
              }
            </Form.Item>
          </Col>
        );
      });
    return children;
  };
  return (
    <Form
      form={form}
      style={{ backgroundColor: "#eee" }}
      name="question-search"
      className="question-search"
      onFinish={onFinish}
    >
      <Row gutter={24}>{getFields()}</Row>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button style={{ margin: "0 8px" }} onClick={() => form.resetFields()}>
            Clear
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const RandomSelector: FC<{
  exams: Exams;
  setQuery: (q: GetQuestionsProp) => void;
  deleteThis: () => void;
  query: GetQuestionsProp;
}> = ({ exams, setQuery, deleteThis, query }) => {
  const [exam, setExam] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [sample, setSample] = useState<number>(1);
  const [tags, setTags] = useState<{ [key: string]: Tag }>({});
  const [form] = Form.useForm();

  const onFieldsChange = (values: any) => {
    // console.log("Received values of form: ", values);
    const { name, value } = values[0];
    const n = name[0];

    const newQuery = { ...query, exam, subject, sample };

    if (value) {
      setQuery({
        ...newQuery,
        properties: { ...newQuery.properties, [n]: value },
      });
    } else {
      let old = { ...newQuery.properties };
      delete old[name];
      setQuery({ ...newQuery, properties: old });
    }
  };

  useEffect(() => {
    const tags =
      exam && exams[exam] && subject && exams[exam].subjects[subject] ? exams[exam].subjects[subject].tags : {};
    setTags(tags);
    setQuery({ ...query, exam: exam, subject: subject });
  }, [exams, exam, subject]);

  useEffect(() => {
    setExam("");
    setSubject("");
    setTags({});
    setSample(1);
    form.resetFields();
  }, [exams]);

  const getFields = () => {
    const children = [];
    children.push(
      <Col span={8} key="subject">
        <Form.Item
          name={`subject`}
          label={`Subject`}
          rules={[
            {
              required: true,
              message: "Select target subject to start",
            },
          ]}
        >
          <SubjectSelector
            exams={exams}
            setSubject={(s) => {
              form.setFieldsValue({ subject: s });
              const [exam, subject] = s.split("#");
              setExam(exam);
              setSubject(subject);
            }}
          />
        </Form.Item>
      </Col>
    );
    children.push(
      <Col span={8} key="sample">
        <Form.Item name={`sample`} label={`Sample`} rules={[{ required: true }]}>
          <InputNumber
            placeholder={`1 - 5`}
            style={{ width: "100%" }}
            min={1}
            max={5}
            value={1}
            onChange={(v) => setSample(v ? v : sample)}
          />
        </Form.Item>
      </Col>
    );

    Object.entries(tags)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .forEach((entry) => {
        const [tagName, tag] = entry;
        children.push(
          <Col span={8} key={tagName}>
            <Form.Item
              name={`${tag.type}#${tag.name}`}
              label={toUpperCase(tag.name)}
              rules={[
                {
                  required: false,
                  message: "Input something!",
                },
              ]}
            >
              {
                {
                  integer: (
                    <InputNumber
                      placeholder={`${(tag as TagInt).min} - ${(tag as TagInt).max}`}
                      style={{ width: "100%" }}
                      min={(tag as TagInt).min}
                      max={(tag as TagInt).max}
                      value={tag.default}
                      onChange={(v) => form.setFieldsValue({ [`integer#${tag.name}`]: v })}
                    />
                  ),
                  string:
                    tag.type === "string" ? (
                      <Select
                        allowClear
                        placeholder="please select an option"
                        value={tag.default}
                        onChange={(v) => form.setFieldsValue({ [`string#${tag.name}`]: v })}
                      >
                        {(tag as TagStr).options?.sort(sort).map((option) => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <></>
                    ),
                  "multi-level":
                    tag.type === "multi-level" ? (
                      <TreeSelect
                        allowClear
                        placeholder="please select an option"
                        treeData={Object.values((tag as TagMul).options)
                          ?.sort((a, b) => sort(a.name, b.name))
                          .map(({ name, options }) => {
                            return {
                              title: name,
                              value: name,
                              children: options?.sort(sort).map((option) => {
                                return {
                                  title: option,
                                  value: `${name}@${option}`,
                                };
                              }),
                            };
                          })}
                        onChange={(v) => form.setFieldsValue({ [`multi-level#${tag.name}`]: v })}
                      />
                    ) : (
                      <></>
                    ),
                }[tag.type]
              }
            </Form.Item>
          </Col>
        );
      });
    return children;
  };
  return (
    <Form
      form={form}
      style={{ backgroundColor: "#eee" }}
      name="question-search"
      className="question-search"
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={24}>{getFields()}</Row>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button style={{ margin: "0 8px" }} onClick={() => deleteThis()}>
            Delete
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export { RandomSelector, Selector };
