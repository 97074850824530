import { FC } from "react";
import { Layout, Space } from "antd";

const { Content } = Layout;

const ErrorPage: FC = () => {
  return (
    <Content>
      <Space style={{ marginTop: 80, minHeight: "200px", marginLeft: "20px", marginRight: "20px" }}>
        <text>Error. Invalid path.</text>
      </Space>
    </Content>
  );
};

export { ErrorPage };
