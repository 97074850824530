import { FC } from "react";
import { Layout, Menu, Space } from "antd";
import { Outlet, NavLink, useLocation } from "react-router-dom";

const { Header, Footer } = Layout;

const navItems = [
  {
    label: "Topical Past Papers",
    path: "/questions/1",
  },
  {
    label: "Special Past Papers",
    path: "/questions/2",
  },
  {
    label: "Topical Exam Builder",
    path: "/builder/1",
  },
  {
    label: "Special Exam Builder",
    path: "/builder/2",
  },
];

const Page: FC = () => {
  const path = useLocation().pathname;
  const selectedKey = navItems.map((item) => item.path).includes(path)
    ? path
    : "";
  return (
    <Layout>
      <Header style={{ position: "fixed", zIndex: 99, width: "100%" }}>
        <Menu theme="dark" mode="horizontal" selectedKeys={[selectedKey]}>
          {navItems.map(({ label, path }) => (
            <Menu.Item key={path}>
              <NavLink to={path}>{label}</NavLink>
            </Menu.Item>
          ))}
        </Menu>
      </Header>
      <Outlet />
      <Footer style={{ textAlign: "center" }}>
        <Space direction="vertical">
          MyQuestionBank @2022
          <a href="https://beian.miit.gov.cn/" target="_blank">
            闽ICP备2022016009号-1
          </a>
        </Space>
      </Footer>
    </Layout>
  );
};

export { Page };
