import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Button, Layout, List, message, Modal, Space } from "antd";
import { isMobile } from "react-device-detect";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useLoaderData } from "react-router-dom";

import { ErrorPage } from "../../components/template/error";
import { Question } from "../../components/question";
import { QuestionList } from "../../components/questionList";
import { Selector } from "../../components/selector";

import type { GetQuestionsProp, OrderBy } from "../../utils/apis";
import { pageSize } from "../../utils/apis";

import "./index.scss";

const { Content } = Layout;

interface QueryType {
  [key: string]: string | number | OrderBy;
}

const OrderByButton: FC<{
  dbType: string;
  query: QueryType;
  field: string;
  getQuestions: (dbType: string, prop: GetQuestionsProp) => Promise<Array<Question>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setQuery: Dispatch<SetStateAction<QueryType>>;
  setQuestions: Dispatch<SetStateAction<{ total: number; questions: Array<Question> }>>;
}> = ({ dbType, query, field, setLoading, setQuery, setQuestions, getQuestions }) => {
  const order = () => {
    const { orderBy, ...rest } = query;
    const newQuery: QueryType = {
      ...rest,
      orderBy: {
        field: `properties.${field}`,
        orderType: orderBy && (orderBy as OrderBy)?.orderType === "asc" ? "desc" : "asc",
      },
    };
    setQuery(newQuery);
    setLoading(true);
    getQuestions(dbType, { ...newQuery, page: 1 }).then((questions) => {
      setLoading(false);
      setQuestions((prev) => {
        return { ...prev, questions: questions };
      });
    });
  };

  return (
    <Button style={{ width: "100px" }} onClick={order}>
      <Space>
        {field[0].toUpperCase() + field.slice(1)}{" "}
        {(query.orderBy as OrderBy)?.field === `properties.${field}` ? (
          (query.orderBy as OrderBy)?.orderType === "asc" ? (
            <UpOutlined />
          ) : (
            <DownOutlined />
          )
        ) : (
          <></>
        )}
      </Space>
    </Button>
  );
};

interface QuestionProps {
  getQuestions: (dbType: string, prop: GetQuestionsProp) => Promise<Array<Question>>;
  getQuestionsCount: (dbType: string, prop: GetQuestionsProp) => Promise<number>;
}

const MaxQuestionListLength = 20;

const Questions: FC<QuestionProps> = ({ getQuestions, getQuestionsCount }) => {
  const { exams, dbType } = useLoaderData() as { exams: Exams; dbType: string };
  const [questions, setQuestions] = useState<{ total: number; questions: Array<Question> }>({
    total: 0,
    questions: [],
  });
  const [open, setOpen] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [query, setQuery] = useState<QueryType>({});
  const [current, setCurrent] = useState<number>(1);
  const [question, setQuestion] = useState<Question>();
  const [questionList, setQuestionList] = useState<Array<Question>>([]);

  useEffect(() => {
    setCurrent(1);
  }, [query, exams]);

  const [loading, setLoading] = useState<boolean>(false);

  const questionInList: boolean = question !== undefined && questionList.includes(question);

  const questionHasAnswer: boolean =
    question && questions.questions.some((q) => q._id === question._id)
      ? questions.questions
          .find((q) => q._id === question._id)!
          .blocks.some((b) => b.type === "textAnswer" || b.type === "imageAnswer")
      : false;

  const handleQuery = (q: Query) => {
    let query: Record<string, any> = {};
    if (q.exam !== "") {
      query.exam = q.exam;
    }
    if (q.subject !== "") {
      query.subject = q.subject;
    }
    if (Object.keys(q.properties).length !== 0) {
      query["properties"] = {};
      Object.entries(q.properties).forEach(([n, v]) => {
        query["properties"][n] = v;
      });
    }
    if (query.exam && query.subject && dbType) {
      const fetchTotal = getQuestionsCount(dbType, query);
      const fetchQuestions = getQuestions(dbType, query);
      setLoading(true);
      Promise.all([fetchTotal, fetchQuestions]).then(([total, questions]) => {
        setQuestions({ total: total, questions: questions });
        setQuery(query);
        setLoading(false);
      });
    }
  };

  const appendToQuestionList = (question: Question | undefined) => {
    if (questionList.length >= MaxQuestionListLength) {
      message.warning(`You can only add ${MaxQuestionListLength} questions at most to generate PDF.`);
      return;
    }
    if (question && !questionList.includes(question)) {
      setQuestionList((prev) => {
        return [...prev, question];
      });
    }
  };

  const removeFromQuestionList = (question: Question | undefined) => {
    if (question && questionList.includes(question)) {
      setQuestionList((prev) => {
        return prev.filter((qid) => qid !== question);
      });
    }
  };

  let modalButtons = null;

  const addModalButtons = () => {
    if (questionHasAnswer || !isMobile) {
      modalButtons = [];
    }

    if (questionHasAnswer) {
      modalButtons?.push(
        <Button danger={showAnswer} key="showAnswer" onClick={() => setShowAnswer(!showAnswer)}>
          {showAnswer ? "Hide Answer" : "Show Answer"}
        </Button>
      );
    }

    if (!isMobile) {
      modalButtons?.push(
        <Button
          danger={questionInList}
          key="addToGenerateList"
          onClick={() => (questionInList ? removeFromQuestionList(question) : appendToQuestionList(question))}
        >
          {questionInList ? "Remove from Generate List" : "Add to Generate List"}
        </Button>
      );
    }
  };

  addModalButtons();

  useEffect(() => {
    const fetchTotal = getQuestionsCount(dbType, query);
    const fetchQuestions = getQuestions(dbType, {});

    setLoading(true);

    Promise.all([fetchQuestions, fetchTotal]).then(([questions, total]) => {
      setQuestions({ total: total, questions: questions });
      setLoading(false);
    });
  }, [dbType]);

  if (!dbType) return <ErrorPage />;

  return (
    <>
      <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
        <Space
          direction="horizontal"
          align="start"
          style={{
            columnGap: 15,
            display: "flex",
            margin: "auto",
            justifyContent: "center",
            maxWidth: "90vw",
          }}
        >
          <Space
            direction="vertical"
            style={{
              display: "flex",
              margin: "auto",
              marginTop: 80,
              width: "21cm",
              maxWidth: "max(60vw, 380px)",
            }}
          >
            <Selector exams={exams} setQuery={handleQuery} />
            <Space>
              <OrderByButton
                dbType={dbType}
                field="year"
                query={query}
                setQuery={setQuery}
                setLoading={setLoading}
                setQuestions={setQuestions}
                getQuestions={getQuestions}
              />
              <OrderByButton
                dbType={dbType}
                field="mark"
                query={query}
                setQuery={setQuery}
                setLoading={setLoading}
                setQuestions={setQuestions}
                getQuestions={getQuestions}
              />
              <OrderByButton
                dbType={dbType}
                field="paper"
                query={query}
                setQuery={setQuery}
                setLoading={setLoading}
                setQuestions={setQuestions}
                getQuestions={getQuestions}
              />
            </Space>
            <List
              itemLayout="vertical"
              size="large"
              split={false}
              rowKey="_id"
              loading={loading}
              pagination={{
                onChange: (page) => {
                  setCurrent(page);
                  setLoading(true);
                  getQuestions(dbType, { ...query, page: page }).then((questions) => {
                    setLoading(false);
                    setQuestions((prev) => {
                      return { ...prev, questions: questions };
                    });
                  });
                },
                current: current,
                pageSize: pageSize,
                pageSizeOptions: [pageSize],
                total: questions.total,
              }}
              dataSource={questions.questions}
              renderItem={(question) => (
                <List.Item className="question-card" style={{ flexDirection: "column", padding: "15px" }}>
                  <Question
                    watermark
                    data={question}
                    onClick={() => {
                      setShowAnswer(false);
                      setOpen(true);
                      setQuestion(question);
                    }}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginTop: "10px",
                    }}
                  >
                    <Button size="small" onClick={() => appendToQuestionList(question)}>
                      Add to Generate List
                    </Button>
                  </div>
                </List.Item>
              )}
            />
          </Space>
          <QuestionList questionList={questionList} setQuestionList={setQuestionList} />
        </Space>
      </Content>
      <Modal
        bodyStyle={{ height: "90vh", overflowY: "scroll" }}
        centered
        open={open}
        onCancel={() => setOpen(false)}
        width={"21cm"}
        footer={modalButtons}
      >
        <Question watermark showAnswer={showAnswer} data={question!} />
      </Modal>
    </>
  );
};

export { Questions };
