import { FC } from "react";
import { createHashRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";

import { ErrorPage } from "./components/template/error";
import { Page } from "./components/template";
import { Questions } from "./pages/questions";
import { ExamBuilder } from "./pages/examBuilder";
import { getQuestions, getQuestionsCount, getTags } from "./utils/apis";

import type { Params } from "react-router-dom";
import "./App.scss";

const loadTags = async ({ params }: { params: Params }) => {
  const { type } = params;
  if (!type) return {};
  switch (type) {
    case "1":
    case "2": {
      return { dbType: type, exams: await getTags(type)() };
    }
    default: {
      return {};
    }
  }
};

const App: FC = () => {
  let router = createHashRouter(
    createRoutesFromElements(
      <Route path="/" element={<Page />}>
        <Route
          index
          loader={async () => {
            return { dbType: "1", exams: await getTags("1")() };
          }}
          element={<Questions getQuestions={getQuestions} getQuestionsCount={getQuestionsCount} />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/questions/:type"
          loader={loadTags}
          element={<Questions getQuestions={getQuestions} getQuestionsCount={getQuestionsCount} />}
          errorElement={<ErrorPage />}
        />
        <Route path="/builder/:type" loader={loadTags} element={<ExamBuilder />} errorElement={<ErrorPage />} />
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export default App;
