import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { Button, Input, List, Space, Switch, Typography } from "antd";
import { isChrome } from "react-device-detect";

import { Question } from "../question";
import { GeneratePDF } from "../generate";
import { MoveQuestion } from "../moveQuestion";

import type { useReactToPrint } from "../../components/generate";

const { Text, Title } = Typography;

const QuestionList: FC<{
  loading?: boolean;
  questionList: Array<Question>;
  setQuestionList: Dispatch<SetStateAction<Array<Question>>>;
}> = ({ loading = false, questionList, setQuestionList }) => {
  // parameters for PDF generation
  const [generateAnswer, setGenerateAnswer] = useState<boolean>(false);
  const [generateBlankSpace, setGenerateBlankSpace] = useState<boolean>(false);

  const [title, setTitle] = useState<string>("");

  // reference to handlePrint function in GeneratePDF component
  const handlePrint = useRef<useReactToPrint | null>(null);

  const clearQuestionList = () => setQuestionList([]);

  const removeFromQuestionList = (question: Question | undefined) => {
    if (question && questionList.includes(question)) {
      setQuestionList((prev) => {
        return prev.filter((qid) => qid !== question);
      });
    }
  };

  return (
    <>
      {!loading && questionList.length > 0 ? (
        <Space direction="vertical" style={{ margin: "auto", marginTop: 80, minHeight: 300 }}>
          <Title level={5}>Selected QuestionList</Title>
          <List
            itemLayout="horizontal"
            size="large"
            split={false}
            dataSource={questionList}
            style={{
              padding: 10,
              borderRadius: 10,
              backgroundColor: "#f0f0f0",
            }}
            renderItem={(question, idx) => (
              <List.Item
                style={{
                  flexDirection: "column",
                  margin: "15px 0px",
                  padding: 0,
                }}
              >
                <Space direction="vertical">
                  <Space>
                    <Text strong>{`Q ${idx + 1}`}</Text>{" "}
                    <MoveQuestion idx={idx + 1} questions={questionList} setQuestions={setQuestionList} />
                    <a style={{ color: "red" }} onClick={() => removeFromQuestionList(question)}>
                      delete
                    </a>
                  </Space>
                  <Question watermark brief data={question} />
                </Space>
              </List.Item>
            )}
          ></List>
          {isChrome ? (
            <Space direction="vertical">
              <Input addonBefore="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
              <Space direction="horizontal">
                <Switch
                  checkedChildren="Show Answer"
                  unCheckedChildren="Hide Answer"
                  defaultChecked={generateAnswer}
                  onChange={(e) => setGenerateAnswer(e)}
                />
                <Switch
                  checkedChildren="Show Blank Space"
                  unCheckedChildren="Hide Blank Space"
                  defaultChecked={generateBlankSpace}
                  onChange={(e) => setGenerateBlankSpace(e)}
                />
              </Space>
              <Space direction="horizontal">
                <Button type="primary" onClick={() => handlePrint.current!()}>
                  Generate PDF
                </Button>
                <GeneratePDF
                  title={title}
                  answer={generateAnswer}
                  blankSpace={generateBlankSpace}
                  questions={questionList}
                  generateRef={handlePrint}
                />
                <Button danger onClick={clearQuestionList}>
                  Clear
                </Button>
              </Space>
            </Space>
          ) : (
            <Text strong>{`PDF generation is only available on desktop Chrome browser`}</Text>
          )}
        </Space>
      ) : (
        <></>
      )}
    </>
  );
};

export { QuestionList };
