import { FC } from "react";
import { Image, List, Space, Typography } from "antd";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { decodeSpecialChar } from "../../utils/decode";
import { toUpperCase } from "../../utils/toUpperCase";

import "./index.scss";

const { Text } = Typography;

const config = {
  "fast-preview": {
    disabled: false,
  },
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
  svg: {
    fontCache: "global",
  },
  renderMode: "post",
};

const Question: FC<{
  data: Question;
  showAnswer?: boolean;
  showBlank?: boolean;
  brief?: boolean;
  watermark?: boolean;
  index?: number;
  onClick?: () => void;
}> = ({ data, showAnswer = false, showBlank = false, brief = false, watermark = false, index, onClick }) => {
  const nonAnswerBlocks = data.blocks?.filter((b) => b.type !== "textAnswer" && b.type !== "imageAnswer");
  return (
    <div
      className={"question"}
      style={{
        width: brief ? "30vw" : "",
        maxWidth: brief ? "450px" : "",
      }}
      onClick={onClick}
    >
      <Space direction="vertical" style={{ display: "flex" }} className={watermark ? "question-watermark" : ""}>
        <MathJaxContext version={3} config={config}>
          <List
            split={false}
            dataSource={brief ? (nonAnswerBlocks.length > 0 ? [nonAnswerBlocks[0]] : [data.blocks[0]]) : data.blocks}
            renderItem={(block, idx) => (
              <List.Item className="question-item">
                {index && idx === 0 ? (
                  <div className="question-index">
                    <Text strong>{`${index}.  `}</Text>
                    <Text>{`[Maximum mark: ${data.properties?.mark}]`}</Text>
                  </div>
                ) : (
                  <></>
                )}

                {block.type === "image" ? (
                  <Image preview={false} src={block.data.file.url} className={brief ? "brief-image" : ""} />
                ) : block.type === "paragraph" ? (
                  <MathJax dynamic className={brief ? "brief" : ""} style={{ display: brief ? "-webkit-box" : "" }}>
                    {decodeSpecialChar(block.data.content)}
                  </MathJax>
                ) : block.type === "imageAnswer" && showAnswer ? (
                  <div className="answer image-answer" style={{ flexDirection: "column" }}>
                    <Text strong>Answer:</Text>
                    <Image preview={false} src={block.data.file.url} className={brief ? "brief-image" : ""} />
                  </div>
                ) : block.type === "textAnswer" && showAnswer ? (
                  <div className="answer text-answer" style={{ flexDirection: "column" }}>
                    <Text strong>Answer:</Text>
                    <MathJax dynamic>{decodeSpecialChar(block.data.content)}</MathJax>
                  </div>
                ) : block.type === "blankLine" && showBlank ? (
                  <List
                    style={{ width: "100%" }}
                    dataSource={[...Array(block.data.number).keys()]}
                    renderItem={(i) => (
                      <hr
                        style={{
                          marginTop: "2em",
                          height: "0px",
                          border: "none",
                          borderTop: "2px solid black",
                        }}
                        key={i}
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </List.Item>
            )}
          />
        </MathJaxContext>
        {brief ? (
          <></>
        ) : (
          <Space wrap className="tags">
            <Space>
              <Text strong>Year:</Text>
              <Text>{data.properties?.year ? data.properties.year : "--"}</Text>
            </Space>
            <Space>
              <Text strong>Mark:</Text>
              <Text>{data.properties?.mark ? data.properties.mark : "--"}</Text>
            </Space>
            <Space>
              <Text strong>TimeZone:</Text>
              <Text>{data.properties?.timezone ? data.properties.timezone : "--"}</Text>
            </Space>
            <Space>
              <Text strong>Paper:</Text>
              <Text>{data.properties?.paper ? data.properties.paper : "--"}</Text>
            </Space>
          </Space>
        )}
      </Space>
    </div>
  );
};

export { Question };
