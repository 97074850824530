import { FC, useState, useEffect } from "react";
import { Button, Layout, List, message, Space } from "antd";
import { useLoaderData } from "react-router-dom";

import { ErrorPage } from "../../components/template/error";
import { QuestionList } from "../../components/questionList";
import { RandomSelector } from "../../components/selector";
import { getRandomQuestions } from "../../utils/apis";

import type { GetQuestionsProp } from "../../utils/apis";

import "./index.scss";

const { Content } = Layout;

const MaxNumberOfQuery = 3;

const ExamBuilder: FC = () => {
  const { dbType, exams } = useLoaderData() as { dbType: string; exams: Exams };
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [queries, setQueries] = useState<Array<GetQuestionsProp>>([{}]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setQueries([{}]);
    setQuestions([]);
  }, [dbType, exams]);

  const setQuery = (idx: number) => (query: GetQuestionsProp) =>
    setQueries((prev) => {
      return [...prev.slice(0, idx), query, ...prev.slice(idx + 1)];
    });
  const deleteQuery = (idx: number) => () =>
    setQueries((prev) =>
      prev.filter((_, _idx) => {
        return _idx !== idx;
      })
    );

  const reachMaxNumberOfQuery = queries.length >= MaxNumberOfQuery;

  const addNewQuery = () => (reachMaxNumberOfQuery ? {} : setQueries((prev) => [...prev, {}]));

  if (!dbType) return <ErrorPage />;

  return (
    <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{
          columnGap: 15,
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          maxWidth: "90vw",
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            margin: "auto",
            marginTop: 80,
            width: "21cm",
            maxWidth: "max(60vw, 380px)",
          }}
        >
          <List
            style={{ width: "100%" }}
            itemLayout="vertical"
            size="large"
            split={false}
            dataSource={queries}
            renderItem={(_, idx) => (
              <List.Item style={{ flexDirection: "column", padding: "10px" }}>
                <RandomSelector
                  query={queries[idx]}
                  exams={exams}
                  setQuery={setQuery(idx)}
                  deleteThis={deleteQuery(idx)}
                />
              </List.Item>
            )}
          />
          <Space
            direction="horizontal"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button disabled={reachMaxNumberOfQuery} style={{ width: "150px" }} onClick={() => addNewQuery()}>
              Add more
            </Button>
            <Button
              type="primary"
              disabled={queries.length < 1 || queries.filter((q) => q.exam).length === 0}
              style={{ width: "150px" }}
              onClick={async () => {
                setLoading(true);
                const result = await getRandomQuestions(dbType, queries);
                if (result.length === 0) {
                  message.error("No question is found with the given constraints.");
                  setQuestions([]);
                  setLoading(false);
                } else {
                  setQuestions(result);
                  setLoading(false);
                }
              }}
            >
              Shuffle
            </Button>
          </Space>
        </Space>
        <QuestionList loading={loading} questionList={questions} setQuestionList={setQuestions} />
      </Space>
    </Content>
  );
};

export { ExamBuilder };
