import axios from "axios";

const baseURL =
  "https://myquestionbank-9gcfve68fe4574af-1313159134.ap-shanghai.app.tcloudbase.com";

const pageSize = 5;

interface OrderBy {
  field: string;
  orderType: "asc" | "desc";
}

interface GetQuestionsProp {
  page?: number;
  exam?: string;
  subject?: string;
  sample?: number;
  orderBy?: OrderBy;
  properties?: { [key: string]: string | number };
}

const getQuestions = (dbType: string, query: GetQuestionsProp) => {
  const { exam, subject, page, properties, orderBy } = query;
  let q: {
    [key: string]:
      | string
      | number
      | {
          field: string;
          orderType: "asc" | "desc";
        };
  } = {};

  if (exam && subject) {
    q = { exam: exam, subject: subject };
  }
  if (orderBy) q.orderBy = orderBy;

  if (properties) {
    Object.entries(properties)
      .filter(([, v]) => v)
      .forEach(([n, v]) => (q[n] = v));
  }
  if (page) q.page = page;

  if (dbType) q.dbType = dbType;

  // console.log("API query", q);
  return axios({
    method: "get",
    url: "/questions",
    baseURL: baseURL,
    params: q,
  })
    .then((res) => {
      // console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const getRandomQuestions = (
  dbType: string,
  queries: Array<GetQuestionsProp>
) => {
  if (
    !(
      queries &&
      Array.isArray(queries) &&
      queries.length > 0 &&
      Object.keys(queries[0]).length > 0
    )
  ) {
    return [1];
  }
  const qs = queries.map((query) => {
    const { exam, subject, sample, properties } = query;
    let q: { [key: string]: string | number } = {};
    if (exam && subject) {
      q = { exam: exam, subject: subject, sample: sample ? sample : 1 };
    }
    if (properties) {
      Object.entries(properties)
        .filter(([, v]) => v)
        .forEach(([n, v]) => (q[n] = v));
    }
    return q;
  });
  // console.log(qs);
  return axios({
    method: "get",
    url: "/random",
    baseURL: baseURL,
    params: { queries: qs, dbType },
  })
    .then((res) => {
      // console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const getQuestionsCount = (dbType: string, query: GetQuestionsProp) => {
  const { exam, subject, properties } = query;
  let q: { [key: string]: string | number } = { dbType };

  if (exam && subject) {
    q = { ...q, exam, subject };
  }
  if (properties) {
    Object.entries(properties)
      .filter(([, v]) => v)
      .forEach(([n, v]) => (q[n] = v));
  }
  return axios({
    method: "get",
    url: "/qcnt",
    baseURL: baseURL,
    params: q,
  })
    .then((res) => {
      // console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const getTags = (dbType: string) => () =>
  axios({
    method: "get",
    url: "/tags",
    baseURL: baseURL,
    params: { dbType },
  })
    .then((res) => {
      // console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });

export {
  getQuestions,
  getQuestionsCount,
  getRandomQuestions,
  getTags,
  pageSize,
};
export type { GetQuestionsProp, OrderBy };
